import firebase from 'firebase'
var config = {
    apiKey: "AIzaSyBQJ_E-Cm6-WPrmsjkOK2VQk_GYez1ZnUs",
    authDomain: "majority-fold.firebaseapp.com",
    databaseURL: "https://majority-fold.firebaseio.com",
    projectId: "majority-fold",
    storageBucket: "majority-fold.appspot.com",
    messagingSenderId: "510809076014",
    appId: "1:510809076014:web:42a8b387ac21a7d606cabf"
  };
firebase.initializeApp(config);
export default firebase;
