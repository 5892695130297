import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie';
import slugify from 'slugify';

import Card from './Card'

import deck from '../util/deck'
import shuffle from '../util/shuffle'
import pluckCards from '../util/pluckCards'
import emojis from '../util/emojis'
import './Table.scss'

import firebase from '../util/firebase'
const shuffled = shuffle(deck)

const playersRef = firebase.database().ref('players')
const handRef = firebase.database().ref('hand')

const Player = ({data, activePlayer, dealer, progress, cards}) => {
  const [showWallet, setShowWallet] = useState(false)
  const walletAmount = data.roundBet ? data.wallet - data.roundBet : data.wallet
  const renderIcon = status => {
    if(dealer === data.index){
      return '🤵🏻'
    }
    else if(status === 'snoozing'){
      return '😴'
    }
    else if(status === 'folded'){
      return '😔'
    }
    else{
      return data.icon
    }
  }
  const isActive = () => data.index === activePlayer && progress < 4

  return(
    <div className="player">
      {data.roundBet > 0 &&
        <div className="player__message">
          + ${data.roundBet.toFixed(2)}
        </div>
      }
      {data.handBet > 0 &&
        <div className="player__bet">
          💵 ${data.handBet.toFixed(2)}
        </div>
      }
      <div className="player__badge" style={{opacity: data.status !== 'playing' ? '0.3' : '1', borderColor: isActive() ? '#31E563' : '#000000'}} onMouseEnter={() => setShowWallet(true)} onMouseLeave={() => setShowWallet(false)}>
        {renderIcon(data.status)} {data.name}
      </div>
      {showWallet &&
        <div className="player__wallet">
        💰 ${walletAmount.toFixed(2)}
          <span className="caps">From ${data.buyIn} buy in</span>
        </div>
      }
      {progress === 4 && data.status === 'playing' &&
        <div className="player__hand">
          {cards.map(card => <Card code={card} />)}
        </div>
      }
    </div>
  )
}

const Draw = ({ deck, progress }) => {
  const cards = deck.slice(0, 5)

  const checkProgress = val => {
    switch (val) {
      case 0:
        return 0
        break;
      case 1:
        return 3
        break;
      case 2:
        return 4
        break;
      case 3:
        return 5
        break;
      case 4:
        return 5
        break;
      default:
        return 0
    }
  }

  const numVisible = checkProgress(progress)

  return(
    <div className="draw">
      {cards.map((code, index) => <Card code={code} flipped={index > (numVisible - 1) ? true : false}/>)}
    </div>
  )
}

const Dash = ({ players, player, cards, shuffle, updateStatus, fold, progress, bet, activePlayer, roundBet, nextPlayer, payOut, prev }) => {
  const [betting, setBetting] = useState(false)
  const [splitting, setSplitting] = useState(false)
  const [triggerUpdate, setTriggerUpdate] = useState(false)
  const [winners, setWinners] = useState([])

  let betAmounts = [
    0.25,
    0.5,
    1,
    1.5,
    2,
    2.5,
    3,
    4,
    5
  ]

  let wallets = []

  Object.keys(players).map(p => {
    if(players[p]['status'] === 'playing'){
      wallets.push([players[p]['wallet']])
    }
  })

  let poorest = Math.min(...wallets)

  const MaxBet = () => {
    if(poorest === 0){
      return null
    }
    else if(player.wallet === poorest){
      return (
        <span className="action" onClick={() => sendBet(player.wallet)}>
          🎲 All in
        </span>
      )
    }
    else{
      return (
        <span className="action" onClick={() => sendBet(poorest)}>
          ${poorest.toFixed(2)}
        </span>
      )
    }
  }

  let validAmounts = betAmounts.filter(x => x > roundBet && x < player.wallet)
  let walletAmount = player.roundBet ? player.wallet - player.roundBet : player.wallet
  let playersForNextHand = Object.values(players).filter(p => p.status === 'playing' || p.status === 'new' || p.status === 'folded' )

  const sendBet = amount => {
    bet(amount)
    setBetting(false)
  }

  const sendSplit = winners => {
    payOut(winners)
    setSplitting(false)
  }

  const toggleSplit = key => {
    let tempWinners = winners
    if(tempWinners.length > 0 && tempWinners.includes(key)){
      let pos = tempWinners.indexOf(key)
      tempWinners.splice(pos, 1)
    }
    else{
      tempWinners.push(key)
    }
    setWinners(tempWinners)
    setTriggerUpdate(!triggerUpdate)
  }

  let pot = 0

  Object.keys(players).map(key => {
    pot = pot + players[key].handBet
    if(players[key].roundBet){
      pot += players[key].roundBet
    }
  })

  return(
    <div className="dash">
      { progress < 0 && prev &&
        <div className="dash__message">
          <p>
          {prev.winners.map(w => {
            let p = players[w]
            return(
              <div className="player__badge">
                {p.icon} {p.name}
              </div>
            )
          })}

          won&nbsp;&nbsp;💵${prev.split.toFixed(2)} {prev.winners.length > 1 && ' each'}</p>
        </div>
      }
      { progress < 0 && player.status !== 'broke' &&
        <div className="dash__actions">
          {playersForNextHand.length > 1 &&
            <span className="action" onClick={() => shuffle()}>
              🤵🏻 Deal new hand
            </span>
          }
        </div>
      }
      {progress < 0 && playersForNextHand.length < 2 &&
        <div className="dash__message">
          <p>You need at least 2 players to deal the next hand.</p>
        </div>
      }
      { progress === 4 && !splitting &&
        <>
        <div className="dash__pot">
        Total Pot 💸${pot.toFixed(2)}
        </div>
        <div className="dash__message">
          <p>Who won?</p>
        </div>
        <div className="dash__actions">
        {Object.keys(players).map(key => {
          let pl = players[key]
          if(pl.status === 'playing'){
            return (
              <span className="action" onClick={() => payOut([key])}>
                {pl.icon} {pl.name}
              </span>
            )
          }
          })
        }
        <span className="action" onClick={() => setSplitting(true)}>
          💔 Split pot
        </span>
        </div>
        </>
      }
      { progress === 4 && splitting &&
        <>
        <div className="dash__message">
          <p>Between who?</p>
        </div>
        <div className="dash__actions">
        {Object.keys(players).map(key => {
          let pl = players[key]
          if(pl.status === 'playing'){
            return (
              <span className="action" onClick={() => toggleSplit(key)}>
                {winners.includes(key) ? '✅' : pl.icon} {pl.name}
              </span>
            )
          }
          })
        }
        <span className="action" onClick={() => sendSplit(winners)}>
          Ok
        </span>
        <span className="action" onClick={() => setSplitting(false)}>
          Cancel
        </span>
        </div>
        </>
      }
      {player.status === 'playing' && activePlayer === player.index && progress < 4 && progress > -1 &&
        <>
          <div className="dash__message">
            <p>{betting ? "How much?" : "It's your turn."}</p>
          </div>
          <div className="dash__actions">
            {!betting &&
            <>
              <span className="action" onClick={() => bet(roundBet)}>
                { roundBet === 0 ? '👊 Check' : `😌 Call $${roundBet.toFixed(2)}`}
                {roundBet === player.wallet && ' (All in)'}
              </span>
              {poorest > 0 &&
                <>
                  { roundBet !== player.wallet &&
                    <span className="action" onClick={() => setBetting(true)}>
                      🤠 { roundBet > 0 ? 'Raise' : 'Bet' }
                    </span>
                  }
                  <span className="action" onClick={() => fold()}>
                    😔 Fold
                  </span>
                </>
              }
            </>
            }
            {betting &&
            <>
            {validAmounts.map(amount => (
              <span className="action" onClick={() => sendBet(amount) }>
                ${amount.toFixed(2)}
              </span>)
            )}
              <MaxBet />
              <span className="action" onClick={() => setBetting(false)}>
                Cancel
              </span>
            </>
            }
          </div>
        </>
      }
      {(player.status === 'playing' || player.status === 'folded') && progress > -1 &&
        <div className="dash__hand" style={{opacity: player.status === 'folded' ? '0.3' : '1'}}>
          {cards.map(card => <Card code={card} />)}
        </div>
      }
      {player.status === 'new' &&
        <div className="dash__message">
          <p>👋 You'll be dealt in next hand.</p>
        </div>
      }
      {player.status === 'folded' &&
        <div className="dash__message">
          <p>😔 You've folded.</p>
        </div>
      }
      {player.status === 'snoozing' &&
        <>
        <div className="dash__message">
          <p>😴 You're snoozing.</p>
        </div>
        <div className="dash__actions">
          <span className="action" onClick={() => updateStatus('new')}>
            🤵🏻 Deal me in on the next hand
          </span>
        </div>
        </>
      }
      { (player.status === 'folded' || player.status === 'new') &&
        <div className="dash__snooze" onClick={() => updateStatus('snoozing')}>
          <span>
            😴
          </span>
        </div>
      }
      <div className="dash__wallet">
        💰 ${walletAmount.toFixed(2)}
        <span className="caps">From ${player.buyIn} buy in</span>
      </div>
    </div>
  )
}

const EntryForm = ({submit}) => {
  const [name, setName] = useState(null)
  const [icon, setIcon] = useState(null)
  return(
    <div className="entry-form">
      <p>What's your name?</p>
      <input type="text" maxlength="6" onChange={e => setName(e.target.value)}/>
      <p>What's your emoji?</p>
      <div className="emojis">
        {
          emojis.map(e => <div onClick={() => setIcon(e)} class={e === icon ? 'selected' : ''}><span>{e}</span></div>)
        }
      </div>
      {name && icon &&
        <span className="action" onClick={() => submit(name, icon)}>
          🚪 Enter the room
        </span>
      }
    </div>
  )
}

const Table = () => {
  const [players, setPlayers] = useState(false)
  const [deck, setDeck] = useState(false)
  const [prev, setPrev] = useState(false)
  const [progress, setProgress] = useState(0)
  const [dealer, setDealer] = useState(0)
  const [activePlayer, setActivePlayer] = useState(0)
  const [cookies, setCookie] = useCookies(['playerID'])
  const [player, setPlayer] = useState(false)
  const [playerOrder, setPlayerOrder] = useState(false)
  const [playerCards, setPlayerCards] = useState(false)
  const [roundBet, setRoundBet] = useState(0)

  useEffect(() => {
    playersRef.on('value', snapshot => setPlayers(snapshot.val()), [])
    handRef.on('value', snapshot => setDeck(snapshot.val()['deck']), [])
    handRef.on('value', snapshot => setPrev(snapshot.val()['prev']), [])
    handRef.on('value', snapshot => setProgress(snapshot.val()['progress']), [])
    handRef.on('value', snapshot => setDealer(snapshot.val()['dealer']), [])
    handRef.on('value', snapshot => setRoundBet(snapshot.val()['roundBet']), [])
    handRef.on('value', snapshot => setActivePlayer(snapshot.val()['activePlayer']), [])
  }, [])

  useEffect(() => {
    const playerArray = []

    Object.keys(players).map(p => playerArray.push([players[p]['index'], p]))
    setPlayer(players[cookies.playerID])
    playerArray.sort((a,b) => a[0] - b[0])
    setPlayerOrder(playerArray)
  }, [players])

  const handleNewPlayer = (name, icon) => {
    let d = new Date();
    d.setDate(d.getDate() + 1);

    if(!cookies.playerID){
      let tempPlayers = players
      let newPlayer = slugify(name)
      let pLength = Object.keys(tempPlayers).length
      if(!tempPlayers.hasOwnProperty(newPlayer)){
        setCookie('playerID', newPlayer, { path: '/', expires: d })
        tempPlayers[newPlayer] = {
            name: name,
            icon: icon,
            roundBet: 0,
            handBet: 0,
            buyIn: 50,
            wallet: 50,
            index: pLength,
            status: 'new'
        }
      }
      playersRef.set(tempPlayers)
    }
  }

  const findNextPlayer = currentPlayer => {
    let twoLoops = playerOrder.concat(playerOrder)
    twoLoops = twoLoops.slice(currentPlayer + 1, twoLoops.length)
    let nextIndex = 0
    twoLoops.some(p => {
      let index = p[0]
      let id = p[1]
      if(players[id]['status'] === 'playing'){
        nextIndex = index
        return true
      }
    })
    return nextIndex
  }

  const findNextPlayerAll = currentPlayer => {
    let twoLoops = playerOrder.concat(playerOrder)
    let threeLoops = twoLoops.concat(playerOrder)
    threeLoops = threeLoops.slice(currentPlayer + 1, threeLoops.length)
    let nextIndex = 0
    threeLoops.some(p => {
      let index = p[0]
      let id = p[1]
      if(players[id]['status'] !== 'snoozing' && players[id]['status'] !== 'broke'){
        nextIndex = index
        return true
      }
    })
    return nextIndex
  }

  const isRoundOver = allPlayers => {
    let pArray = Object.values(allPlayers)
    let playing = pArray.filter(p => p.status === 'playing')
    let roundBets = playing.map(p => p.roundBet)
    let isOver = !roundBets.includes(false) && roundBets.every( (val, i, arr) => val === arr[0] )
    return isOver
  }

  const nextPlayer = () => {
    handRef.set({
      deck: deck,
      progress: progress,
      dealer: dealer,
      activePlayer: findNextPlayer(activePlayer),
      roundBet: roundBet,
      prev: prev
    })
  }

  const updateStatus = newStatus => {
    let tempPlayers = players
    let tempPlayer = player
    tempPlayer['status'] = newStatus
    tempPlayers[cookies.playerID] = tempPlayer
    playersRef.set(tempPlayers)
  }

  const handlePayOut = winners => {
    let pot = 0
    Object.keys(players).map(key => {
      pot = pot + players[key].handBet
      if(players[key].roundBet){
        pot += players[key].roundBet
        players[key].roundBet = false
      }
    })
    let split = pot / winners.length
    let tempPlayers = players

    Object.keys(tempPlayers).map(key => {
      if(winners.includes(key)){
        tempPlayers[key].wallet = tempPlayers[key].wallet + split
      }
      tempPlayers[key].handBet = 0
    })

    playersRef.set(tempPlayers)

    const handStats = {
      split: split,
      winners: winners
    }

    handRef.set({
      deck: deck,
      progress: -1,
      dealer: dealer,
      activePlayer: activePlayer,
      roundBet: 0,
      prev: handStats
    })
  }

  const handleFold = () => {
    let tempPlayers = players
    let tempPlayer = player
    let moneySpent = tempPlayer['roundBet']
    tempPlayer['status'] = 'folded'
    tempPlayer['wallet'] = tempPlayer['wallet'] - moneySpent
    tempPlayer['handBet'] = tempPlayer['handBet'] + moneySpent
    tempPlayer['roundBet'] = false
    tempPlayers[cookies.playerID] = tempPlayer
    const over = isRoundOver(tempPlayers)
    let stillPlaying = Object.keys(tempPlayers).filter(key => tempPlayers[key].status === 'playing')

    if(stillPlaying.length < 2){
      //there's only one player left in the hand
      handlePayOut(stillPlaying)
    }
    else if(over){
      Object.keys(tempPlayers).map(key => {
        if(tempPlayers[key].roundBet > 0){
          tempPlayers[key].handBet = tempPlayers[key].handBet + tempPlayers[key].roundBet
        }
        tempPlayers[key].roundBet = false
      })

      handRef.set({
        deck: deck,
        progress: progress + 1,
        dealer: dealer,
        activePlayer: findNextPlayer(activePlayer),
        roundBet: 0,
        prev: prev
      })
    }
    else{
      nextPlayer()
    }

    playersRef.set(tempPlayers)

  }

  const handleBet = amount => {
    let tempPlayers = players
    let tempPlayer = player
    tempPlayer['roundBet'] = amount
    tempPlayers[cookies.playerID] = tempPlayer
    const over = isRoundOver(tempPlayers)
    if(over){
      Object.keys(tempPlayers).map(key => {
        if(tempPlayers[key].roundBet > 0){
          tempPlayers[key].handBet = tempPlayers[key].handBet + tempPlayers[key].roundBet
          tempPlayers[key]['wallet'] = tempPlayers[key]['wallet'] - tempPlayers[key]['roundBet']
        }
        tempPlayers[key].roundBet = false
      })
    }

    playersRef.set(tempPlayers)

    handRef.set({
      deck: deck,
      progress: over ? progress + 1 : progress,
      dealer: dealer,
      activePlayer: over ? findNextPlayer(dealer) : findNextPlayer(activePlayer),
      roundBet: over ? 0 : amount,
      prev: prev
    })
  }

  const handleShuffle = () => {
    const oldDeck = deck
    const newDeck = shuffle(oldDeck)
    let tempPlayers = players
    const newDealer = findNextPlayerAll(dealer)
    const smallBlind = findNextPlayerAll(newDealer)
    const bigBlind = findNextPlayerAll(smallBlind)
    Object.keys(players).map(key => {
      const status = tempPlayers[key]['status']
      if(tempPlayers[key]['wallet'] <= 0){
        tempPlayers[key]['status'] = 'broke'
      }
      else if(status === 'new' || status === 'folded'){
        tempPlayers[key]['status'] = 'playing'
      }
      if(smallBlind == tempPlayers[key]['index']){
        tempPlayers[key]['roundBet'] = 0.25
      }
      else if(bigBlind == tempPlayers[key]['index']){
        tempPlayers[key]['roundBet'] = 0.5
      }
      else{
        tempPlayers[key]['roundBet'] = false
      }
    })
    playersRef.set(tempPlayers)
    handRef.set({
      deck: newDeck,
      progress: 0,
      dealer: newDealer,
      activePlayer: findNextPlayerAll(newDealer),
      roundBet: 0.5,
      prev: prev
    })

  }

  const handleProgress = () => {
    handRef.set({
      deck: deck,
      progress: progress + 1,
      dealer: dealer,
      activePlayer: activePlayer,
      roundBet: 0,
      prev: prev
    })
  }

  const numPlayers = Object.keys(players).length

  return(
    <>
    <div className="mobile-message">
      <p>😬 Sorry, this app is built for desktop.</p>
    </div>
    <div className="table">
      {players && player &&
        <div className="seats">
          {playerOrder.map(p => <Player data={players[p[1]]} dealer={dealer} activePlayer={activePlayer} progress={progress} cards={pluckCards(Object.values(deck), players[p[1]].index)} />)}
        </div>
      }
      {!player && players && numPlayers < 7 &&
        <>
        <div className="seats seats--preview">
          <p>
            {numPlayers} players currently at the table <br/>
            Buy in is 💰$50
          </p>
          <div>
            { Object.values(players).map(player => <div className="player__badge">{player.icon} {player.name}</div>) }
          </div>
        </div>
        <EntryForm submit={handleNewPlayer}/>
        </>
      }
      {players && !player && numPlayers >= 7 &&
        <div className="seats seats--preview">
          <p>
            Sorry, there are already {numPlayers} at the table <br/>
          </p>
          <div>
            { Object.values(players).map(player => <div className="player__badge">{player.icon} {player.name}</div>) }
          </div>
        </div>
      }
      {players && player &&
        <Draw deck={Object.values(deck)} progress={progress}/>
      }
      {players && player &&
        <Dash players={players} player={player} prev={prev} cards={pluckCards(Object.values(deck), player.index)} shuffle={handleShuffle} updateStatus={updateStatus} fold={handleFold} progress={progress} activePlayer={activePlayer} bet={handleBet} roundBet={roundBet} nextPlayer={nextPlayer} payOut={handlePayOut}/>
      }
    </div>
    </>
  )
}

export default Table
