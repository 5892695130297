import React from 'react'

import heart from '../svg/heart.svg'
import diamond from '../svg/diamond.svg'
import club from '../svg/club.svg'
import spade from '../svg/spade.svg'

import './Card.scss'

const Card = ({code, flipped}) => {
  const suit = code[0]
  const value = code.substring(1)
  let image = ''
  const setSuit = () => {
    switch (suit) {
      case 'h':
        image = heart
        break;
      case 'd':
        image = diamond
        break;
      case 'c':
        image = club
        break;
      case 's':
        image = spade
        break;
      default:
        image = heart
    }
  }
  setSuit()
  return(
    <div className={flipped ? `card card--flipped` : `card card--${suit}`}>
      <div>
        { !flipped &&
          <>
            <span>{value}</span>
            <img src={image} alt="symbol"/>
            <span>{value}</span>
          </>
        }
      </div>
    </div>
  )
}

export default Card
